import './howItWorks.scss';

const HowItWorks = ({ how_it_works }) => (
  <section className="how-it-works">
    <div className="container">
      <div className="section-title">
        <span>Price</span>

        <h3>How It Works</h3>
      </div>

      <div className="how-it-works__steps">
        {how_it_works?.map((el, index) => (
          <StepCard key={index} data={el} index={index} />
        ))}
      </div>
    </div>
  </section>
);

export default HowItWorks;

const StepCard = ({ data, index }) => (
  <div className="step-card">
    <p className="number">{index + 1}</p>
    <h4>{data.title}</h4>
    <p className="text">{data.description}</p>
  </div>
);
