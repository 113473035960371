import Slider from 'react-slick';

import './reviews.scss';

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Reviews = ({ testimonials }) => {
  return (
    <section className="reviews">
      <div className="container">
        <div className="section-title">
          <span>Testimonials</span>

          <h3>What Our Clients Have to Say</h3>
        </div>

        <Slider className="reviews__list" {...sliderSettings}>
          {testimonials?.map((el, index) => (
            <ReviewCard key={index} data={el} />
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Reviews;

const ReviewCard = ({ data }) => (
  <div className="reviews__list--card">
    <div className="reviews__list--card__photo">
      <img src={data.image} alt={data.name} />
    </div>
    <div className="reviews__list--card__content">
      <div className="reviews__list--card__content--header">
        <p className="name">{data.name}</p>

        <span className="date">{data.date}</span>
      </div>

      <p className="text">{data.text}</p>
    </div>
  </div>
);
