import './pricing.scss';

const Pricing = ({ pricing_plans }) => (
  <section className="pricing">
    <div className="container">
      <div className="section-title">
        <span>Price</span>

        <h3>Pricing Plans</h3>
      </div>

      <div className="pricing__list">
        {pricing_plans?.map((el, index) => (
          <PriceCard key={index} data={el} />
        ))}
      </div>
    </div>
  </section>
);

export default Pricing;

const PriceCard = ({ data }) => (
  <div className="pricing__list--card">
    <div className="pricing__list--card__header">
      <h5>{data.title}</h5>
    </div>
    <div className="pricing__list--card__body">
      <span className="price">$ {data.price}</span>

      <span className="time">From {data.duration} hours</span>

      <ul>
        {data.includes.map((el, index) => (
          <li key={index}>{el.item}</li>
        ))}
      </ul>

      <a href="https://booking.coast2coastrental.net/" className="yellow-button" target="_blank" rel="noReferrer">
        Book a Picnic
      </a>
    </div>
  </div>
);
