import './packages.scss';

const Packages = ({ packages }) => (
  <section className="packages">
    <div className="container">
      <div className="section-title">
        <span>Packages</span>

        <h3>Featured Packages</h3>
      </div>

      <div className="packages__list">
        {packages?.map((el, index) => (
          <PackageCard key={index} data={el} />
        ))}
      </div>
    </div>
  </section>
);

export default Packages;

const PackageCard = ({ data }) => (
  <div className="packages__list--item">
    <div
      className="packages__list--item__image"
      style={{
        backgroundImage: `url(${data.image})`,
      }}
    />
    <div className="packages__list--item__wrapper">
      <div className="packages__list--item__content">
        <h4>{data.title}</h4>
        <div dangerouslySetInnerHTML={{ __html: data.description }} />
      </div>
      <div className="packages__list--item__info">
        <span className="time">{data.duration} Hrs</span>
        <span className="coast">{data.coast}</span>
        <span className="people">{data.people} people</span>
      </div>
      <a href="https://booking.coast2coastrental.net/" className="yellow-button" target="_blank" rel="noReferrer">
        Book Now
      </a>
    </div>
  </div>
);
