import { useState, useEffect } from 'react';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import { Layout, Seo, Spinner } from '../components';
import { Hero, About, Packages, Portfolio, Reviews, Pricing, HowItWorks, GetInTouch, FollowUs } from '../sections';

const adminURL = 'https://booking.coast2coastrental.net/wp-json/wp/v2';

const Home = () => {
  const [pageData, setPageData] = useState();
  const { isLoading, data, error } = useFetch(`${adminURL}/pages?slug=settings`);

  useEffect(() => {
    if (data) {
      setPageData(data[0]);
    }
  }, [data]);

  useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <Layout pageId="home-page" contacts={pageData?.acf.contacts}>
      <Seo data={pageData?.yoast_head_json} />
      <Hero />
      <About />
      <Packages packages={pageData?.acf.featured_packages} />
      <Portfolio gallery={pageData?.acf.gallery} />
      <Reviews testimonials={pageData?.acf.testimonials} />
      <Pricing pricing_plans={pageData?.acf.pricing_plans} />
      <HowItWorks how_it_works={pageData?.acf.how_it_works} />
      <GetInTouch />
      <FollowUs
        follow_us={pageData?.acf.follow_us}
        instagram={pageData?.acf.contacts.instagram_account}
        link={pageData?.acf.contacts.instagram_link}
      />
    </Layout>
  );
};

export default Home;
