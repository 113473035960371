import { useState, useEffect } from 'react';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import { Layout, Seo, Spinner, ContentBlock } from '../components';

const adminURL = 'https://booking.coast2coastrental.net/wp-json/wp/v2';

const TermsOfUse = () => {
  const [pageData, setPageData] = useState();
  const { isLoading, data, error } = useFetch(`${adminURL}/pages?slug=settings`);

  useEffect(() => {
    if (data) {
      setPageData(data[0]);
    }
  }, [data]);

  useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <Layout pageId="terms-of-use" contacts={pageData?.acf.contacts}>
      <Seo data={pageData?.yoast_head_json} />
      <section>
        <ContentBlock title="Terms of Use" content={pageData?.acf.terms_of_use} />
      </section>
    </Layout>
  );
};

export default TermsOfUse;
