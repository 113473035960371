import './about.scss';

const About = () => (
  <section className="about">
    <div className="container">
      <p>
        <span />
        Are you looking to transform your outdoor or indoor space into a laid-back and jolly surrounding? Then we at
        Coast2Coast picnic got you sorted. <span /> It doesn't matter the occasion you are planning we'll do all the
        work for you. We aim at making your amazing picnic party into the ideal ambiance for memories with your loved
        ones.
        <span />
        No matter the size of the occasion we will make it into a heartfelt experience as you curl up on the coziest
        pillows and blankets.
      </p>
    </div>
  </section>
);

export default About;
