import React from 'react';
import { Link } from 'react-router-dom';

import './header.scss';

const Header = ({ phone, logo }) => {
  const location = window.location.pathname;
  const [shadow, setShadow] = React.useState(false);

  const handleScroll = () => {
    setShadow(window.scrollY > 142);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`page-header ${(shadow || location.split('/')[1] !== '') && 'shadow'}`}>
      <div className="container">
        <Link to="/" className="page-header__logo">
          <img src={logo} alt="Logo" />
        </Link>

        <div className="page-header__links">
          <a href={`tel:${phone}`} className="page-header__phone">
            {phone}
          </a>
          <a
            href="https://booking.coast2coastrental.net/"
            className="page-header__book"
            target="_blank"
            rel="noReferrer"
          >
            Book a Picnic
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
