import { Link, NavLink } from 'react-router-dom';

import './footer.scss';

const Footer = ({ contacts }) => (
  <footer className="page-footer">
    <div className="container">
      <div className="page-footer__contacts">
        <Link to="/" className="page-footer__logo">
          <img src={contacts?.logo} alt="Logo" />
        </Link>

        <a href={`tel:${contacts?.phone}`} className="page-footer__phone">
          {contacts?.phone}
        </a>

        <a href={`mailto:${contacts?.email}`} className="page-footer__email">
          {contacts?.email}
        </a>
      </div>

      <div className="page-footer__social-networks">
        <div className="page-footer__social-networks__inner">
          <p>Follow Us</p>

          <div className="page-footer__social-networks__links">
            <a href={contacts?.instagram_link} className="insta" target="_blank" rel="noReferrer">
              <span>instagram</span>
            </a>
            <a href={contacts?.facebook} className="fb" target="_blank" rel="noReferrer">
              <span>facebook</span>
            </a>
            <a href={contacts?.twitter} className="twitter" target="_blank" rel="noReferrer">
              <span>twitter</span>
            </a>
            <a href={contacts?.youtube} className="youtube" target="_blank" rel="noReferrer">
              <span>youtube</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <Underline />
  </footer>
);

export default Footer;

const Underline = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="container">
      <div className="page-footer__underline">
        <div className="container">
          <span>
            ©{currentYear}. Coast 2 Coast. <NavLink to="/privacy-policy">Privacy Policy</NavLink>
          </span>

          <div className="page-footer__underline--links">
            <NavLink to="/accessibility-statement">Accessibility Statement</NavLink>

            <NavLink to="/terms-of-use">Terms of Use</NavLink>
          </div>
        </div>

        <a href="https://toporin.com" target="_blank" rel="noReferrer">
          Designed by <strong>Toporin Studio®</strong> in California
        </a>
      </div>
    </div>
  );
};
