import './followUs.scss';

const FollowUs = ({ follow_us, instagram, link }) => (
  <section className="follow-us">
    <div className="container">
      <div className="follow-us__header">
        <div className="section-title">
          <span>Instagram</span>

          <h3>Follow Us</h3>
        </div>

        <a href={link} target="_blank" rel="noReferrer">
          {instagram}
        </a>
      </div>

      <div className="follow-us__photos">
        {follow_us?.map((el, index) => (
          <div key={index}>
            <img src={el.photo} alt={`insta ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default FollowUs;
