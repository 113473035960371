import './hero.scss';

const Hero = () => (
  <section className="hero">
    <div className="container">
      <h1>PICNICS</h1>

      <p>save your precious love memories</p>

      <a href="https://booking.coast2coastrental.net/" className="yellow-button" target="_blank" rel="noReferrer">
        Book a Picnic
      </a>
    </div>
  </section>
);

export default Hero;
