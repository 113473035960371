import './portfolio.scss';

const Portfolio = ({ gallery }) => (
  <section className="portfolio">
    <div className="container">
      <div className="section-title">
        <span>Portfolio</span>

        <h3>Gallery of Our Works</h3>
      </div>

      {gallery && (
        <div className="gallery-wrapper">
          <div className="gallery-wrapper__first-line">
            <div>
              <img src={gallery[0]?.photo} alt="gallery item #1" />
            </div>

            <div>
              <img src={gallery[1]?.photo} alt="gallery item #2" />
            </div>
          </div>

          <div className="gallery-wrapper__second-line">
            <div>
              <img src={gallery[2]?.photo} alt="gallery item #3" />
            </div>

            <div>
              <img src={gallery[3]?.photo} alt="gallery item #4" />
            </div>

            <div>
              <img src={gallery[4]?.photo} alt="gallery item #5" />
            </div>
          </div>

          <div className="gallery-wrapper__third-line">
            <div>
              <img src={gallery[5]?.photo} alt="gallery item #6" />
            </div>

            <div>
              <img src={gallery[6]?.photo} alt="gallery item #7" />
            </div>

            <div>
              <img src={gallery[7]?.photo} alt="gallery item #8" style={{ maxHeight: 356 }} />
            </div>
          </div>
        </div>
      )}
    </div>
  </section>
);

export default Portfolio;
