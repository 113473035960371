import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

import './getInTouch.scss';

const MAIL_URL = 'https://booking.coast2coastrental.net/wp-json/contact-form-7/v1/contact-forms/6/feedback';

const GetInTouch = () => {
  const [bookingFrom, setBookingForm] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    date: '',
    address: '',
    message: '',
  });

  const handleChange = (e) => {
    const key = e.target.name;
    const val = e.target.value;

    setBookingForm((prevState) => ({
      ...prevState,
      [key]: val,
    }));
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    Object.entries(bookingFrom).map((el) => formData.append(el[0], el[1]));

    try {
      const response = await axios.post(MAIL_URL, formData);

      if (response.status === 200) {
        toast.success(response.data.message);
        e.target.reset();
      }
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please, try again!');
      console.log(error);
    }
  };

  return (
    <section className="get-in-touch">
      <div className="container">
        <div className="section-title">
          <span>Book Online</span>

          <h3>Get in Touch</h3>
        </div>

        <form onSubmit={sendEmail}>
          <fieldset>
            <input type="text" required name="first_name" placeholder="First Name *" onChange={handleChange} />
            <input type="text" required name="last_name" placeholder="Last Name *" onChange={handleChange} />
            <input type="email" required name="email" placeholder="Email *" onChange={handleChange} />
            <input type="tel" required name="phone" placeholder="Phone *" onChange={handleChange} />
          </fieldset>
          <fieldset>
            <input type="text" required name="date" placeholder="Picnic Date *" onChange={handleChange} />
            <input type="text" required name="address" placeholder="Address *" onChange={handleChange} />
            <textarea name="message" placeholder="Any special requests/ Celebration?" onChange={handleChange} />
          </fieldset>

          <button type="submit" className="yellow-button">
            Submit
          </button>
        </form>
      </div>
    </section>
  );
};

export default GetInTouch;
